import { useEffect } from 'react';

import { useApiAccess } from 'contexts/AccessProvider';
import { useExtrinsicDetailsQuery } from 'gql';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes/routesConst';

import OverviewDetails from './components/OverviewDetails';

const useExtrinsicDetails = () => {
  const navigate = useNavigate();
  const { id: idOrHash } = useParams();
  const { sessionKey } = useApiAccess();

  const extrinsic = idOrHash?.startsWith('0x')
    ? { extrinsicHash: idOrHash }
    : { extrinsicId: idOrHash };

  const {
    data: extrinsicDetailsData,
    loading: isLoading,
    error,
  } = useExtrinsicDetailsQuery({
    variables: { sessionKey, ...extrinsic },
  });

  useEffect(() => {
    if (error?.message) {
      navigate(routes[404]);
    }
  }, [error, navigate]);

  const overviewDetails = OverviewDetails({ extrinsicDetailsData });

  return {
    isLoading,
    extrinsicDetailsData,
    overviewDetails,
  };
};

export default useExtrinsicDetails;
