import { DetailsList } from '@analog/ui';
import { Heading, SearchBox } from 'components';

import ExtrinsicEventList from './components/ExtrinsicEventList';
import useExtrinsicDetails from './useExtrinsicDetails';

const ExtrinsicDetails = () => {
  const { isLoading, extrinsicDetailsData, overviewDetails } = useExtrinsicDetails();

  return (
    <>
      <div className="flex flex-col gap-4 md:gap-8">
        <Heading
          title={`Extrinsics #${extrinsicDetailsData?.extrinsicDetails?.id}`}
          subTitle="Featured: Review and revoke DApp access to your tokens with our Token Approvals tool!"
          isLoading={isLoading}
        />
        <SearchBox defaultSearchType={'Extrinsics'} />
      </div>
      <div className="flex flex-col mt-8 md:mt-[60px] gap-8 lg:gap-10">
        <DetailsList
          title={'Overview'}
          details={overviewDetails}
          isLoading={isLoading}
          className="bg-[#060606]"
        />
        <ExtrinsicEventList />
      </div>
    </>
  );
};

export default ExtrinsicDetails;
