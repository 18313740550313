import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: any; output: any; }
  /** Json custom scalar type */
  Json: { input: any; output: any; }
};

export type AccountsWithCount = {
  __typename?: 'AccountsWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Accounts>>>;
};

export type BlockWithCount = {
  __typename?: 'BlockWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Block>>>;
};

export type EventWithCount = {
  __typename?: 'EventWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Events>>>;
};

export type ExtrinsicWithCount = {
  __typename?: 'ExtrinsicWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Extrinsics>>>;
};

export type MembersWithCount = {
  __typename?: 'MembersWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Members>>>;
};

export type Query = {
  __typename?: 'Query';
  accountDetails: Accounts;
  blockDetails?: Maybe<Block>;
  extrinsicDetails?: Maybe<Extrinsics>;
  getExtrinsics: ExtrinsicWithCount;
  healthCheck?: Maybe<Scalars['Json']['output']>;
  listAccounts: AccountsWithCount;
  listBlocks: BlockWithCount;
  listEvents: EventWithCount;
  listExtrinsics: ExtrinsicWithCount;
  listMembers: MembersWithCount;
  listShards: ShradWithCount;
  listTasks: TaskWithCount;
  listValidators?: Maybe<ValidatorsWithCount>;
  memberDetails: Members;
  search: Array<SearchResult>;
  searchEvent?: Maybe<SearchEventType>;
  shardDetails?: Maybe<Shards>;
  supportedChains?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taskDetails: Tasks;
  telemetry: Telemetry;
  validatorDetails?: Maybe<Validators>;
};


export type QueryAccountDetailsArgs = {
  address: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBlockDetailsArgs = {
  blockHash?: InputMaybe<Scalars['String']['input']>;
  blockNumber?: InputMaybe<Scalars['Int']['input']>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryExtrinsicDetailsArgs = {
  extrinsicHash?: InputMaybe<Scalars['String']['input']>;
  extrinsicId?: InputMaybe<Scalars['String']['input']>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetExtrinsicsArgs = {
  blockNumber?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  signer?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
};


export type QueryHealthCheckArgs = {
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAccountsArgs = {
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
};


export type QueryListBlocksArgs = {
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
};


export type QueryListEventsArgs = {
  blockNumber?: InputMaybe<Scalars['String']['input']>;
  extrinsicId?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListExtrinsicsArgs = {
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
};


export type QueryListMembersArgs = {
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
};


export type QueryListShardsArgs = {
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
};


export type QueryListTasksArgs = {
  page?: InputMaybe<PageInputType>;
  phase?: InputMaybe<Scalars['String']['input']>;
  salt?: InputMaybe<Scalars['String']['input']>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  shardId?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
  taskId?: InputMaybe<Array<Scalars['Int']['input']>>;
  taskType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListValidatorsArgs = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMemberDetailsArgs = {
  accountId: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchArgs = {
  searchTerm: Scalars['String']['input'];
  searchType: SearchType;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchEventArgs = {
  action?: InputMaybe<Scalars['String']['input']>;
  blockFrom?: InputMaybe<Scalars['Int']['input']>;
  blockNumber?: InputMaybe<Scalars['Int']['input']>;
  blockTo?: InputMaybe<Scalars['Int']['input']>;
  eventId?: InputMaybe<Scalars['String']['input']>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryShardDetailsArgs = {
  ShardId: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySupportedChainsArgs = {
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTaskDetailsArgs = {
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['Int']['input'];
};


export type QueryTelemetryArgs = {
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};


export type QueryValidatorDetailsArgs = {
  address: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
};

export type SearchFilters = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  searchType?: InputMaybe<SearchType>;
};

export type SearchResult = Accounts | Block | Events | Extrinsics | Members | Shards | Tasks | Validators;

export enum SearchType {
  Accounts = 'accounts',
  Block = 'block',
  Events = 'events',
  Extrinsics = 'extrinsics',
  Members = 'members',
  Shards = 'shards',
  Tasks = 'tasks',
  Validators = 'validators'
}

export type ShradWithCount = {
  __typename?: 'ShradWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Shards>>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newBlocks?: Maybe<BlockSubscriptionType>;
  newExtrinsics?: Maybe<Extrinsics>;
  newTasks?: Maybe<TaskSubscriptionType>;
};

export type TaskWithCount = {
  __typename?: 'TaskWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Tasks>>>;
};

export type ValidatorsWithCount = {
  __typename?: 'ValidatorsWithCount';
  count: Scalars['Int']['output'];
  data?: Maybe<Array<Maybe<Validators>>>;
};

export type Accounts = {
  __typename?: 'accounts';
  address: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  data: Scalars['Json']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type Block = {
  __typename?: 'block';
  author?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  error: Scalars['Boolean']['output'];
  error_message?: Maybe<Scalars['String']['output']>;
  extrinsic_root: Scalars['String']['output'];
  extrinsicsCount?: Maybe<Scalars['BigInt']['output']>;
  hash: Scalars['String']['output'];
  nextBlock?: Maybe<Scalars['Int']['output']>;
  number: Scalars['Int']['output'];
  parent_hash?: Maybe<Scalars['String']['output']>;
  previousBlock?: Maybe<Scalars['Int']['output']>;
  state_root: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type BlockSubscriptionType = {
  __typename?: 'blockSubscriptionType';
  block?: Maybe<Block>;
  extrinsicsCount?: Maybe<Scalars['Int']['output']>;
};

export type Events = {
  __typename?: 'events';
  action: Scalars['String']['output'];
  data: Scalars['Json']['output'];
  eventId: Scalars['String']['output'];
  extrinsicHash: Scalars['String']['output'];
  extrinsicId: Scalars['String']['output'];
};

export type Extrinsics = {
  __typename?: 'extrinsics';
  args: Scalars['Json']['output'];
  assets_transfer?: Maybe<Scalars['String']['output']>;
  block_number: Scalars['Int']['output'];
  created_at: Scalars['DateTime']['output'];
  estimated_fee?: Maybe<Scalars['String']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  life_time?: Maybe<Scalars['Json']['output']>;
  method: Scalars['String']['output'];
  nextExtrinsic?: Maybe<Scalars['String']['output']>;
  nonce?: Maybe<Scalars['String']['output']>;
  previousExtrinsic?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  section: Scalars['String']['output'];
  signature?: Maybe<Scalars['String']['output']>;
  signer?: Maybe<Scalars['String']['output']>;
  used_fee?: Maybe<Scalars['String']['output']>;
};

export type Members = {
  __typename?: 'members';
  account_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  heartbeat?: Maybe<Scalars['Int']['output']>;
  modified_at_block?: Maybe<Scalars['Int']['output']>;
  network: Scalars['String']['output'];
  proposed_at_block?: Maybe<Scalars['Int']['output']>;
  shardId?: Maybe<Scalars['String']['output']>;
  sig: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
};

export type PageInputType = {
  number?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchEventType = {
  __typename?: 'searchEventType';
  block?: Maybe<Block>;
  events?: Maybe<Array<Maybe<Events>>>;
  latestBlock?: Maybe<Block>;
};

export type Shards = {
  __typename?: 'shards';
  commitment?: Maybe<Scalars['Json']['output']>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  members: Array<Members>;
  modified_at_block?: Maybe<Scalars['Int']['output']>;
  network: Scalars['String']['output'];
  proposed_at_block: Scalars['Int']['output'];
  sig?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
};

export type SortInputType = {
  field?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<SortOrderType>;
};

export enum SortOrderType {
  Asc = 'asc',
  Desc = 'desc'
}

export type TaskSubscriptionType = {
  __typename?: 'taskSubscriptionType';
  task?: Maybe<Tasks>;
};

export type Tasks = {
  __typename?: 'tasks';
  childTask?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  definition?: Maybe<Scalars['Json']['output']>;
  destTxHash?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  modified_at_block?: Maybe<Scalars['Int']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['Json']['output']>;
  phase?: Maybe<Scalars['String']['output']>;
  proposed_at_block?: Maybe<Scalars['Int']['output']>;
  salt?: Maybe<Scalars['String']['output']>;
  shard?: Maybe<Shards>;
  shardId?: Maybe<Scalars['String']['output']>;
  sig?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  sourceNetwork?: Maybe<Scalars['String']['output']>;
  srcBlock?: Maybe<Scalars['String']['output']>;
  srcTxHash?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unAssignedReason?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type Telemetry = {
  __typename?: 'telemetry';
  active_members: Scalars['BigInt']['output'];
  finalized_blocks: Scalars['BigInt']['output'];
  shards_live: Scalars['BigInt']['output'];
  tasks_created: Scalars['BigInt']['output'];
  transfers: Scalars['BigInt']['output'];
};

/** Validators on timechain */
export type Validators = {
  __typename?: 'validators';
  address?: Maybe<Scalars['String']['output']>;
  commission?: Maybe<Scalars['String']['output']>;
  discovered?: Maybe<Scalars['String']['output']>;
  faults?: Maybe<Scalars['String']['output']>;
  inValidReason?: Maybe<Scalars['String']['output']>;
  inclusion?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  rewards?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type ListBlocksQueryVariables = Exact<{
  page?: InputMaybe<PageInputType>;
  sort?: InputMaybe<SortInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListBlocksQuery = {
  __typename?: 'Query';
  listBlocks: {
    __typename?: 'BlockWithCount';
    count: number;
    data?: Array<{
      __typename?: 'block';
      number: number;
      hash: string;
      status?: string | null;
      author?: string | null;
      parent_hash?: string | null;
      extrinsic_root: string;
      created_at: any;
    } | null> | null;
  };
};

export type ListBlocksWithExtrinsicsQueryVariables = Exact<{
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListBlocksWithExtrinsicsQuery = {
  __typename?: 'Query';
  listBlocks: {
    __typename?: 'BlockWithCount';
    count: number;
    data?: Array<{
      __typename?: 'block';
      number: number;
      author?: string | null;
      created_at: any;
      extrinsicsCount?: any | null;
    } | null> | null;
  };
};

export type ListEventsQueryVariables = Exact<{
  extrinsicId?: InputMaybe<Scalars['String']['input']>;
  blockNumber?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<PageInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListEventsQuery = {
  __typename?: 'Query';
  listEvents: {
    __typename?: 'EventWithCount';
    count: number;
    data?: Array<{
      __typename?: 'events';
      eventId: string;
      extrinsicId: string;
      extrinsicHash: string;
      action: string;
      data: any;
    } | null> | null;
  };
};

export type ListExtrinsicsQueryVariables = Exact<{
  page?: InputMaybe<PageInputType>;
  sort?: InputMaybe<SortInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListExtrinsicsQuery = {
  __typename?: 'Query';
  listExtrinsics: {
    __typename?: 'ExtrinsicWithCount';
    count: number;
    data?: Array<{
      __typename?: 'extrinsics';
      id: string;
      block_number: number;
      hash: string;
      result?: string | null;
      method: string;
      section: string;
      args: any;
      created_at: any;
    } | null> | null;
  };
};

export type GetExtrinsicsQueryVariables = Exact<{
  page?: InputMaybe<PageInputType>;
  sort?: InputMaybe<SortInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  signer?: InputMaybe<Scalars['String']['input']>;
  blockNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetExtrinsicsQuery = {
  __typename?: 'Query';
  getExtrinsics: {
    __typename?: 'ExtrinsicWithCount';
    count: number;
    data?: Array<{
      __typename?: 'extrinsics';
      id: string;
      block_number: number;
      hash: string;
      result?: string | null;
      method: string;
      section: string;
      args: any;
      created_at: any;
    } | null> | null;
  };
};

export type ListExtrinsicsForSubscriptionQueryVariables = Exact<{
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListExtrinsicsForSubscriptionQuery = {
  __typename?: 'Query';
  listExtrinsics: {
    __typename?: 'ExtrinsicWithCount';
    data?: Array<{
      __typename?: 'extrinsics';
      id: string;
      hash: string;
      method: string;
      created_at: any;
    } | null> | null;
  };
};

export type SearchQueryVariables = Exact<{
  searchType: SearchType;
  searchTerm: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchQuery = {
  __typename?: 'Query';
  search: Array<
    | { __typename?: 'accounts'; accounts_id: string }
    | { __typename?: 'block'; block_id: number; block_hash: string }
    | { __typename?: 'events'; events_id: string }
    | { __typename?: 'extrinsics'; extrinsics_id: string; extrinsics_hash: string }
    | { __typename?: 'members'; members_id: string; members_hash: string }
    | { __typename?: 'shards'; shards_id: string; shards_hash?: string | null }
    | { __typename?: 'tasks'; tasks_id: number; tasks_hash?: string | null }
    | { __typename?: 'validators'; validators_id?: string | null; validators_hash?: string | null }
  >;
};

export type ListTasksQueryVariables = Exact<{
  page?: InputMaybe<PageInputType>;
  sort?: InputMaybe<SortInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  taskType?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  phase?: InputMaybe<Scalars['String']['input']>;
  shardId?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListTasksQuery = {
  __typename?: 'Query';
  listTasks: {
    __typename?: 'TaskWithCount';
    count: number;
    data?: Array<{
      __typename?: 'tasks';
      salt?: string | null;
      created_at: any;
      updated_at: any;
      definition?: any | null;
      destTxHash?: string | null;
      destination?: string | null;
      id: number;
      taskId?: number | null;
      network?: string | null;
      payload?: any | null;
      phase?: string | null;
      shardId?: string | null;
      sig?: string | null;
      source?: string | null;
      sourceNetwork?: string | null;
      type?: string | null;
      childTask?: string | null;
      unAssignedReason?: string | null;
      srcBlock?: string | null;
      srcTxHash?: string | null;
    } | null> | null;
  };
};

export type TaskDetailsQueryVariables = Exact<{
  taskId: Scalars['Int']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type TaskDetailsQuery = {
  __typename?: 'Query';
  taskDetails: {
    __typename?: 'tasks';
    id: number;
    taskId?: number | null;
    type?: string | null;
    shardId?: string | null;
    definition?: any | null;
    sig?: string | null;
    proposed_at_block?: number | null;
    modified_at_block?: number | null;
    created_at: any;
    destination?: string | null;
    network?: string | null;
    payload?: any | null;
    phase?: string | null;
    source?: string | null;
    sourceNetwork?: string | null;
    childTask?: string | null;
    unAssignedReason?: string | null;
    updated_at: any;
    salt?: string | null;
    destTxHash?: string | null;
    srcBlock?: string | null;
    srcTxHash?: string | null;
    shard?: {
      __typename?: 'shards';
      id: string;
      sig?: string | null;
      state: string;
      commitment?: any | null;
      network: string;
    } | null;
  };
};

export type ListShardsQueryVariables = Exact<{
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SortInputType>;
  page?: InputMaybe<PageInputType>;
}>;

export type ListShardsQuery = {
  __typename?: 'Query';
  listShards: {
    __typename?: 'ShradWithCount';
    count: number;
    data?: Array<{
      __typename?: 'shards';
      id: string;
      state: string;
      sig?: string | null;
      commitment?: any | null;
      network: string;
      proposed_at_block: number;
      modified_at_block?: number | null;
    } | null> | null;
  };
};

export type ShardDetailsQueryVariables = Exact<{
  shardId: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ShardDetailsQuery = {
  __typename?: 'Query';
  shardDetails?: {
    __typename?: 'shards';
    id: string;
    state: string;
    sig?: string | null;
    commitment?: any | null;
    network: string;
    created_at: any;
    members: Array<{ __typename?: 'members'; account_id: string }>;
  } | null;
};

export type BlockDetailsQueryVariables = Exact<{
  sessionKey?: InputMaybe<Scalars['String']['input']>;
  blockNumber?: InputMaybe<Scalars['Int']['input']>;
  blockHash?: InputMaybe<Scalars['String']['input']>;
}>;

export type BlockDetailsQuery = {
  __typename?: 'Query';
  blockDetails?: {
    __typename?: 'block';
    number: number;
    hash: string;
    status?: string | null;
    author?: string | null;
    parent_hash?: string | null;
    extrinsic_root: string;
    state_root: string;
    created_at: any;
    error: boolean;
    error_message?: string | null;
    previousBlock?: number | null;
    nextBlock?: number | null;
  } | null;
};

export type ListMembersQueryVariables = Exact<{
  page?: InputMaybe<PageInputType>;
  sort?: InputMaybe<SortInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListMembersQuery = {
  __typename?: 'Query';
  listMembers: {
    __typename?: 'MembersWithCount';
    count: number;
    data?: Array<{
      __typename?: 'members';
      account_id: string;
      network: string;
      state?: string | null;
      sig: string;
      shardId?: string | null;
      heartbeat?: number | null;
      created_at: any;
    } | null> | null;
  };
};

export type ExtrinsicDetailsQueryVariables = Exact<{
  extrinsicId?: InputMaybe<Scalars['String']['input']>;
  extrinsicHash?: InputMaybe<Scalars['String']['input']>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ExtrinsicDetailsQuery = {
  __typename?: 'Query';
  extrinsicDetails?: {
    __typename?: 'extrinsics';
    id: string;
    block_number: number;
    created_at: any;
    life_time?: any | null;
    hash: string;
    signer?: string | null;
    estimated_fee?: string | null;
    signature?: string | null;
    used_fee?: string | null;
    assets_transfer?: string | null;
    nonce?: string | null;
    result?: string | null;
    args: any;
    previousExtrinsic?: string | null;
    nextExtrinsic?: string | null;
  } | null;
};

export type TelemetryQueryVariables = Exact<{
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type TelemetryQuery = {
  __typename?: 'Query';
  telemetry: {
    __typename?: 'telemetry';
    finalized_blocks: any;
    active_members: any;
    transfers: any;
    shards_live: any;
    tasks_created: any;
  };
};

export type SupportedChainsQueryVariables = Exact<{
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type SupportedChainsQuery = {
  __typename?: 'Query';
  supportedChains?: Array<string | null> | null;
};

export type ListValidatorsQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListValidatorsQuery = {
  __typename?: 'Query';
  listValidators?: {
    __typename?: 'ValidatorsWithCount';
    count: number;
    data?: Array<{
      __typename?: 'validators';
      name?: string | null;
      faults?: string | null;
      address?: string | null;
      inclusion?: string | null;
      discovered?: string | null;
      rank?: string | null;
      status?: string | null;
      valid?: boolean | null;
      inValidReason?: string | null;
      score?: string | null;
      commission?: string | null;
      rewards?: string | null;
    } | null> | null;
  } | null;
};

export type ValidatorDetailsQueryVariables = Exact<{
  address: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ValidatorDetailsQuery = {
  __typename?: 'Query';
  validatorDetails?: {
    __typename?: 'validators';
    name?: string | null;
    address?: string | null;
    faults?: string | null;
    inclusion?: string | null;
    discovered?: string | null;
    rank?: string | null;
    status?: string | null;
    valid?: boolean | null;
    inValidReason?: string | null;
    score?: string | null;
    commission?: string | null;
    rewards?: string | null;
  } | null;
};

export type HealthCheckQueryVariables = Exact<{
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type HealthCheckQuery = { __typename?: 'Query'; healthCheck?: any | null };

export type MemberDetailsQueryVariables = Exact<{
  accountId: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type MemberDetailsQuery = {
  __typename?: 'Query';
  memberDetails: {
    __typename?: 'members';
    account_id: string;
    heartbeat?: number | null;
    network: string;
    shardId?: string | null;
    sig: string;
    state?: string | null;
    created_at: any;
  };
};

export type ListAccountsQueryVariables = Exact<{
  page?: InputMaybe<PageInputType>;
  sort?: InputMaybe<SortInputType>;
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListAccountsQuery = {
  __typename?: 'Query';
  listAccounts: {
    __typename?: 'AccountsWithCount';
    count: number;
    data?: Array<{
      __typename?: 'accounts';
      address: string;
      data: any;
      created_at: any;
      updated_at: any;
    } | null> | null;
  };
};

export type AccountDetailsQueryVariables = Exact<{
  address: Scalars['String']['input'];
  sessionKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type AccountDetailsQuery = {
  __typename?: 'Query';
  accountDetails: {
    __typename?: 'accounts';
    address: string;
    data: any;
    created_at: any;
    updated_at: any;
  };
};

export type NewBlocksSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewBlocksSubscription = {
  __typename?: 'Subscription';
  newBlocks?: {
    __typename?: 'blockSubscriptionType';
    extrinsicsCount?: number | null;
    block?: {
      __typename?: 'block';
      number: number;
      hash: string;
      status?: string | null;
      author?: string | null;
      parent_hash?: string | null;
      extrinsic_root: string;
      created_at: any;
    } | null;
  } | null;
};

export type NewExtrinsicsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewExtrinsicsSubscription = {
  __typename?: 'Subscription';
  newExtrinsics?: {
    __typename?: 'extrinsics';
    id: string;
    hash: string;
    method: string;
    created_at: any;
  } | null;
};

export type TaskSubscriptionVariables = Exact<{ [key: string]: never }>;

export type TaskSubscription = {
  __typename?: 'Subscription';
  newTasks?: {
    __typename?: 'taskSubscriptionType';
    task?: {
      __typename?: 'tasks';
      created_at: any;
      salt?: string | null;
      definition?: any | null;
      destination?: string | null;
      id: number;
      network?: string | null;
      payload?: any | null;
      phase?: string | null;
      shardId?: string | null;
      sig?: string | null;
      source?: string | null;
      sourceNetwork?: string | null;
      type?: string | null;
      childTask?: string | null;
      unAssignedReason?: string | null;
    } | null;
  } | null;
};

export const ListBlocksDocument = gql`
  query ListBlocks($page: pageInputType, $sort: sortInputType, $sessionKey: String) {
    listBlocks(page: $page, sort: $sort, sessionKey: $sessionKey) {
      count
      data {
        number
        hash
        status
        author
        parent_hash
        extrinsic_root
        created_at
      }
    }
  }
`;

/**
 * __useListBlocksQuery__
 *
 * To run a query within a React component, call `useListBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBlocksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListBlocksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListBlocksQuery, ListBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListBlocksQuery, ListBlocksQueryVariables>(ListBlocksDocument, options);
}
export function useListBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListBlocksQuery, ListBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListBlocksQuery, ListBlocksQueryVariables>(
    ListBlocksDocument,
    options
  );
}
export type ListBlocksQueryHookResult = ReturnType<typeof useListBlocksQuery>;
export type ListBlocksLazyQueryHookResult = ReturnType<typeof useListBlocksLazyQuery>;
export type ListBlocksQueryResult = Apollo.QueryResult<ListBlocksQuery, ListBlocksQueryVariables>;
export const ListBlocksWithExtrinsicsDocument = gql`
  query ListBlocksWithExtrinsics($page: pageInputType, $sessionKey: String) {
    listBlocks(page: $page, sessionKey: $sessionKey) {
      count
      data {
        number
        author
        created_at
        extrinsicsCount
      }
    }
  }
`;

/**
 * __useListBlocksWithExtrinsicsQuery__
 *
 * To run a query within a React component, call `useListBlocksWithExtrinsicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBlocksWithExtrinsicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBlocksWithExtrinsicsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListBlocksWithExtrinsicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListBlocksWithExtrinsicsQuery,
    ListBlocksWithExtrinsicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListBlocksWithExtrinsicsQuery, ListBlocksWithExtrinsicsQueryVariables>(
    ListBlocksWithExtrinsicsDocument,
    options
  );
}
export function useListBlocksWithExtrinsicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListBlocksWithExtrinsicsQuery,
    ListBlocksWithExtrinsicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListBlocksWithExtrinsicsQuery, ListBlocksWithExtrinsicsQueryVariables>(
    ListBlocksWithExtrinsicsDocument,
    options
  );
}
export type ListBlocksWithExtrinsicsQueryHookResult = ReturnType<
  typeof useListBlocksWithExtrinsicsQuery
>;
export type ListBlocksWithExtrinsicsLazyQueryHookResult = ReturnType<
  typeof useListBlocksWithExtrinsicsLazyQuery
>;
export type ListBlocksWithExtrinsicsQueryResult = Apollo.QueryResult<
  ListBlocksWithExtrinsicsQuery,
  ListBlocksWithExtrinsicsQueryVariables
>;
export const ListEventsDocument = gql`
  query ListEvents(
    $extrinsicId: String
    $blockNumber: String
    $page: pageInputType
    $sessionKey: String
  ) {
    listEvents(
      extrinsicId: $extrinsicId
      blockNumber: $blockNumber
      page: $page
      sessionKey: $sessionKey
    ) {
      count
      data {
        eventId
        extrinsicId
        extrinsicHash
        action
        data
      }
    }
  }
`;

/**
 * __useListEventsQuery__
 *
 * To run a query within a React component, call `useListEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEventsQuery({
 *   variables: {
 *      extrinsicId: // value for 'extrinsicId'
 *      blockNumber: // value for 'blockNumber'
 *      page: // value for 'page'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListEventsQuery, ListEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListEventsQuery, ListEventsQueryVariables>(ListEventsDocument, options);
}
export function useListEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListEventsQuery, ListEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListEventsQuery, ListEventsQueryVariables>(
    ListEventsDocument,
    options
  );
}
export type ListEventsQueryHookResult = ReturnType<typeof useListEventsQuery>;
export type ListEventsLazyQueryHookResult = ReturnType<typeof useListEventsLazyQuery>;
export type ListEventsQueryResult = Apollo.QueryResult<ListEventsQuery, ListEventsQueryVariables>;
export const ListExtrinsicsDocument = gql`
  query ListExtrinsics($page: pageInputType, $sort: sortInputType, $sessionKey: String) {
    listExtrinsics(page: $page, sort: $sort, sessionKey: $sessionKey) {
      count
      data {
        id
        block_number
        hash
        result
        method
        section
        args
        created_at
      }
    }
  }
`;

/**
 * __useListExtrinsicsQuery__
 *
 * To run a query within a React component, call `useListExtrinsicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtrinsicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtrinsicsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListExtrinsicsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListExtrinsicsQuery, ListExtrinsicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListExtrinsicsQuery, ListExtrinsicsQueryVariables>(
    ListExtrinsicsDocument,
    options
  );
}
export function useListExtrinsicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListExtrinsicsQuery, ListExtrinsicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListExtrinsicsQuery, ListExtrinsicsQueryVariables>(
    ListExtrinsicsDocument,
    options
  );
}
export type ListExtrinsicsQueryHookResult = ReturnType<typeof useListExtrinsicsQuery>;
export type ListExtrinsicsLazyQueryHookResult = ReturnType<typeof useListExtrinsicsLazyQuery>;
export type ListExtrinsicsQueryResult = Apollo.QueryResult<
  ListExtrinsicsQuery,
  ListExtrinsicsQueryVariables
>;
export const GetExtrinsicsDocument = gql`
  query GetExtrinsics(
    $page: pageInputType
    $sort: sortInputType
    $sessionKey: String
    $signer: String
    $blockNumber: String
  ) {
    getExtrinsics(
      page: $page
      sort: $sort
      sessionKey: $sessionKey
      signer: $signer
      blockNumber: $blockNumber
    ) {
      count
      data {
        id
        block_number
        hash
        result
        method
        section
        args
        created_at
      }
    }
  }
`;

/**
 * __useGetExtrinsicsQuery__
 *
 * To run a query within a React component, call `useGetExtrinsicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtrinsicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtrinsicsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      sessionKey: // value for 'sessionKey'
 *      signer: // value for 'signer'
 *      blockNumber: // value for 'blockNumber'
 *   },
 * });
 */
export function useGetExtrinsicsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExtrinsicsQuery, GetExtrinsicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExtrinsicsQuery, GetExtrinsicsQueryVariables>(
    GetExtrinsicsDocument,
    options
  );
}
export function useGetExtrinsicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExtrinsicsQuery, GetExtrinsicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExtrinsicsQuery, GetExtrinsicsQueryVariables>(
    GetExtrinsicsDocument,
    options
  );
}
export type GetExtrinsicsQueryHookResult = ReturnType<typeof useGetExtrinsicsQuery>;
export type GetExtrinsicsLazyQueryHookResult = ReturnType<typeof useGetExtrinsicsLazyQuery>;
export type GetExtrinsicsQueryResult = Apollo.QueryResult<
  GetExtrinsicsQuery,
  GetExtrinsicsQueryVariables
>;
export const ListExtrinsicsForSubscriptionDocument = gql`
  query ListExtrinsicsForSubscription($sessionKey: String) {
    listExtrinsics(page: { number: 1, size: 10 }, sessionKey: $sessionKey) {
      data {
        id
        hash
        method
        created_at
      }
    }
  }
`;

/**
 * __useListExtrinsicsForSubscriptionQuery__
 *
 * To run a query within a React component, call `useListExtrinsicsForSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListExtrinsicsForSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListExtrinsicsForSubscriptionQuery({
 *   variables: {
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListExtrinsicsForSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListExtrinsicsForSubscriptionQuery,
    ListExtrinsicsForSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListExtrinsicsForSubscriptionQuery,
    ListExtrinsicsForSubscriptionQueryVariables
  >(ListExtrinsicsForSubscriptionDocument, options);
}
export function useListExtrinsicsForSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListExtrinsicsForSubscriptionQuery,
    ListExtrinsicsForSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListExtrinsicsForSubscriptionQuery,
    ListExtrinsicsForSubscriptionQueryVariables
  >(ListExtrinsicsForSubscriptionDocument, options);
}
export type ListExtrinsicsForSubscriptionQueryHookResult = ReturnType<
  typeof useListExtrinsicsForSubscriptionQuery
>;
export type ListExtrinsicsForSubscriptionLazyQueryHookResult = ReturnType<
  typeof useListExtrinsicsForSubscriptionLazyQuery
>;
export type ListExtrinsicsForSubscriptionQueryResult = Apollo.QueryResult<
  ListExtrinsicsForSubscriptionQuery,
  ListExtrinsicsForSubscriptionQueryVariables
>;
export const SearchDocument = gql`
  query Search($searchType: SearchType!, $searchTerm: String!, $sessionKey: String) {
    search(searchType: $searchType, searchTerm: $searchTerm, sessionKey: $sessionKey) {
      ... on block {
        block_id: number
        block_hash: hash
      }
      ... on events {
        events_id: eventId
      }
      ... on extrinsics {
        extrinsics_id: id
        extrinsics_hash: hash
      }
      ... on tasks {
        tasks_id: id
        tasks_hash: salt
      }
      ... on shards {
        shards_id: id
        shards_hash: sig
      }
      ... on members {
        members_id: account_id
        members_hash: sig
      }
      ... on validators {
        validators_id: address
        validators_hash: name
      }
      ... on accounts {
        accounts_id: address
      }
    }
  }
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      searchType: // value for 'searchType'
 *      searchTerm: // value for 'searchTerm'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const ListTasksDocument = gql`
  query ListTasks(
    $page: pageInputType
    $sort: sortInputType
    $sessionKey: String
    $taskType: String
    $taskId: [Int!]
    $phase: String
    $shardId: String
  ) {
    listTasks(
      page: $page
      sort: $sort
      sessionKey: $sessionKey
      taskType: $taskType
      taskId: $taskId
      phase: $phase
      shardId: $shardId
    ) {
      data {
        salt
        created_at
        updated_at
        definition
        destTxHash
        destination
        id
        taskId
        network
        payload
        phase
        shardId
        sig
        source
        sourceNetwork
        type
        childTask
        unAssignedReason
        srcBlock
        srcTxHash
      }
      count
    }
  }
`;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      sessionKey: // value for 'sessionKey'
 *      taskType: // value for 'taskType'
 *      taskId: // value for 'taskId'
 *      phase: // value for 'phase'
 *      shardId: // value for 'shardId'
 *   },
 * });
 */
export function useListTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, options);
}
export function useListTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListTasksQuery, ListTasksQueryVariables>(ListTasksDocument, options);
}
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<typeof useListTasksLazyQuery>;
export type ListTasksQueryResult = Apollo.QueryResult<ListTasksQuery, ListTasksQueryVariables>;
export const TaskDetailsDocument = gql`
  query TaskDetails($taskId: Int!, $sessionKey: String) {
    taskDetails(taskId: $taskId, sessionKey: $sessionKey) {
      id
      taskId
      type
      shardId
      definition
      sig
      proposed_at_block
      modified_at_block
      created_at
      shard {
        id
        sig
        state
        commitment
        network
      }
      destination
      network
      payload
      phase
      source
      sourceNetwork
      childTask
      unAssignedReason
      updated_at
      salt
      destTxHash
      srcBlock
      srcTxHash
    }
  }
`;

/**
 * __useTaskDetailsQuery__
 *
 * To run a query within a React component, call `useTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useTaskDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(TaskDetailsDocument, options);
}
export function useTaskDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(
    TaskDetailsDocument,
    options
  );
}
export type TaskDetailsQueryHookResult = ReturnType<typeof useTaskDetailsQuery>;
export type TaskDetailsLazyQueryHookResult = ReturnType<typeof useTaskDetailsLazyQuery>;
export type TaskDetailsQueryResult = Apollo.QueryResult<
  TaskDetailsQuery,
  TaskDetailsQueryVariables
>;
export const ListShardsDocument = gql`
  query ListShards($sessionKey: String, $sort: sortInputType, $page: pageInputType) {
    listShards(sessionKey: $sessionKey, page: $page, sort: $sort) {
      count
      data {
        id
        state
        sig
        commitment
        network
        proposed_at_block
        modified_at_block
      }
    }
  }
`;

/**
 * __useListShardsQuery__
 *
 * To run a query within a React component, call `useListShardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListShardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListShardsQuery({
 *   variables: {
 *      sessionKey: // value for 'sessionKey'
 *      sort: // value for 'sort'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useListShardsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListShardsQuery, ListShardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListShardsQuery, ListShardsQueryVariables>(ListShardsDocument, options);
}
export function useListShardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListShardsQuery, ListShardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListShardsQuery, ListShardsQueryVariables>(
    ListShardsDocument,
    options
  );
}
export type ListShardsQueryHookResult = ReturnType<typeof useListShardsQuery>;
export type ListShardsLazyQueryHookResult = ReturnType<typeof useListShardsLazyQuery>;
export type ListShardsQueryResult = Apollo.QueryResult<ListShardsQuery, ListShardsQueryVariables>;
export const ShardDetailsDocument = gql`
  query ShardDetails($shardId: String!, $sessionKey: String) {
    shardDetails(ShardId: $shardId, sessionKey: $sessionKey) {
      id
      state
      sig
      commitment
      network
      created_at
      members {
        account_id
      }
    }
  }
`;

/**
 * __useShardDetailsQuery__
 *
 * To run a query within a React component, call `useShardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShardDetailsQuery({
 *   variables: {
 *      shardId: // value for 'shardId'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useShardDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ShardDetailsQuery, ShardDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShardDetailsQuery, ShardDetailsQueryVariables>(
    ShardDetailsDocument,
    options
  );
}
export function useShardDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShardDetailsQuery, ShardDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShardDetailsQuery, ShardDetailsQueryVariables>(
    ShardDetailsDocument,
    options
  );
}
export type ShardDetailsQueryHookResult = ReturnType<typeof useShardDetailsQuery>;
export type ShardDetailsLazyQueryHookResult = ReturnType<typeof useShardDetailsLazyQuery>;
export type ShardDetailsQueryResult = Apollo.QueryResult<
  ShardDetailsQuery,
  ShardDetailsQueryVariables
>;
export const BlockDetailsDocument = gql`
  query BlockDetails($sessionKey: String, $blockNumber: Int, $blockHash: String) {
    blockDetails(sessionKey: $sessionKey, blockNumber: $blockNumber, blockHash: $blockHash) {
      number
      hash
      status
      author
      parent_hash
      extrinsic_root
      state_root
      created_at
      error
      error_message
      previousBlock
      nextBlock
    }
  }
`;

/**
 * __useBlockDetailsQuery__
 *
 * To run a query within a React component, call `useBlockDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockDetailsQuery({
 *   variables: {
 *      sessionKey: // value for 'sessionKey'
 *      blockNumber: // value for 'blockNumber'
 *      blockHash: // value for 'blockHash'
 *   },
 * });
 */
export function useBlockDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<BlockDetailsQuery, BlockDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlockDetailsQuery, BlockDetailsQueryVariables>(
    BlockDetailsDocument,
    options
  );
}
export function useBlockDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BlockDetailsQuery, BlockDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlockDetailsQuery, BlockDetailsQueryVariables>(
    BlockDetailsDocument,
    options
  );
}
export type BlockDetailsQueryHookResult = ReturnType<typeof useBlockDetailsQuery>;
export type BlockDetailsLazyQueryHookResult = ReturnType<typeof useBlockDetailsLazyQuery>;
export type BlockDetailsQueryResult = Apollo.QueryResult<
  BlockDetailsQuery,
  BlockDetailsQueryVariables
>;
export const ListMembersDocument = gql`
  query ListMembers($page: pageInputType, $sort: sortInputType, $sessionKey: String) {
    listMembers(page: $page, sort: $sort, sessionKey: $sessionKey) {
      count
      data {
        account_id
        network
        state
        sig
        shardId
        heartbeat
        created_at
      }
    }
  }
`;

/**
 * __useListMembersQuery__
 *
 * To run a query within a React component, call `useListMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMembersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<ListMembersQuery, ListMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMembersQuery, ListMembersQueryVariables>(ListMembersDocument, options);
}
export function useListMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMembersQuery, ListMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMembersQuery, ListMembersQueryVariables>(
    ListMembersDocument,
    options
  );
}
export type ListMembersQueryHookResult = ReturnType<typeof useListMembersQuery>;
export type ListMembersLazyQueryHookResult = ReturnType<typeof useListMembersLazyQuery>;
export type ListMembersQueryResult = Apollo.QueryResult<
  ListMembersQuery,
  ListMembersQueryVariables
>;
export const ExtrinsicDetailsDocument = gql`
  query ExtrinsicDetails($extrinsicId: String, $extrinsicHash: String, $sessionKey: String) {
    extrinsicDetails(
      extrinsicId: $extrinsicId
      extrinsicHash: $extrinsicHash
      sessionKey: $sessionKey
    ) {
      id
      block_number
      created_at
      life_time
      hash
      signer
      estimated_fee
      signature
      used_fee
      assets_transfer
      nonce
      result
      args
      previousExtrinsic
      nextExtrinsic
    }
  }
`;

/**
 * __useExtrinsicDetailsQuery__
 *
 * To run a query within a React component, call `useExtrinsicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExtrinsicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExtrinsicDetailsQuery({
 *   variables: {
 *      extrinsicId: // value for 'extrinsicId'
 *      extrinsicHash: // value for 'extrinsicHash'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useExtrinsicDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<ExtrinsicDetailsQuery, ExtrinsicDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExtrinsicDetailsQuery, ExtrinsicDetailsQueryVariables>(
    ExtrinsicDetailsDocument,
    options
  );
}
export function useExtrinsicDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExtrinsicDetailsQuery, ExtrinsicDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExtrinsicDetailsQuery, ExtrinsicDetailsQueryVariables>(
    ExtrinsicDetailsDocument,
    options
  );
}
export type ExtrinsicDetailsQueryHookResult = ReturnType<typeof useExtrinsicDetailsQuery>;
export type ExtrinsicDetailsLazyQueryHookResult = ReturnType<typeof useExtrinsicDetailsLazyQuery>;
export type ExtrinsicDetailsQueryResult = Apollo.QueryResult<
  ExtrinsicDetailsQuery,
  ExtrinsicDetailsQueryVariables
>;
export const TelemetryDocument = gql`
  query Telemetry($sessionKey: String) {
    telemetry(sessionKey: $sessionKey) {
      finalized_blocks
      active_members
      transfers
      shards_live
      tasks_created
    }
  }
`;

/**
 * __useTelemetryQuery__
 *
 * To run a query within a React component, call `useTelemetryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTelemetryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTelemetryQuery({
 *   variables: {
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useTelemetryQuery(
  baseOptions?: Apollo.QueryHookOptions<TelemetryQuery, TelemetryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TelemetryQuery, TelemetryQueryVariables>(TelemetryDocument, options);
}
export function useTelemetryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TelemetryQuery, TelemetryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TelemetryQuery, TelemetryQueryVariables>(TelemetryDocument, options);
}
export type TelemetryQueryHookResult = ReturnType<typeof useTelemetryQuery>;
export type TelemetryLazyQueryHookResult = ReturnType<typeof useTelemetryLazyQuery>;
export type TelemetryQueryResult = Apollo.QueryResult<TelemetryQuery, TelemetryQueryVariables>;
export const SupportedChainsDocument = gql`
  query SupportedChains($sessionKey: String) {
    supportedChains(sessionKey: $sessionKey)
  }
`;

/**
 * __useSupportedChainsQuery__
 *
 * To run a query within a React component, call `useSupportedChainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupportedChainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupportedChainsQuery({
 *   variables: {
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useSupportedChainsQuery(
  baseOptions?: Apollo.QueryHookOptions<SupportedChainsQuery, SupportedChainsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SupportedChainsQuery, SupportedChainsQueryVariables>(
    SupportedChainsDocument,
    options
  );
}
export function useSupportedChainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SupportedChainsQuery, SupportedChainsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SupportedChainsQuery, SupportedChainsQueryVariables>(
    SupportedChainsDocument,
    options
  );
}
export type SupportedChainsQueryHookResult = ReturnType<typeof useSupportedChainsQuery>;
export type SupportedChainsLazyQueryHookResult = ReturnType<typeof useSupportedChainsLazyQuery>;
export type SupportedChainsQueryResult = Apollo.QueryResult<
  SupportedChainsQuery,
  SupportedChainsQueryVariables
>;
export const ListValidatorsDocument = gql`
  query ListValidators($page: Int!, $limit: Int!, $sessionKey: String) {
    listValidators(page: $page, limit: $limit, sessionKey: $sessionKey) {
      count
      data {
        name
        faults
        address
        inclusion
        discovered
        rank
        status
        valid
        inValidReason
        score
        commission
        rewards
      }
    }
  }
`;

/**
 * __useListValidatorsQuery__
 *
 * To run a query within a React component, call `useListValidatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListValidatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListValidatorsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListValidatorsQuery(
  baseOptions: Apollo.QueryHookOptions<ListValidatorsQuery, ListValidatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListValidatorsQuery, ListValidatorsQueryVariables>(
    ListValidatorsDocument,
    options
  );
}
export function useListValidatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListValidatorsQuery, ListValidatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListValidatorsQuery, ListValidatorsQueryVariables>(
    ListValidatorsDocument,
    options
  );
}
export type ListValidatorsQueryHookResult = ReturnType<typeof useListValidatorsQuery>;
export type ListValidatorsLazyQueryHookResult = ReturnType<typeof useListValidatorsLazyQuery>;
export type ListValidatorsQueryResult = Apollo.QueryResult<
  ListValidatorsQuery,
  ListValidatorsQueryVariables
>;
export const ValidatorDetailsDocument = gql`
  query ValidatorDetails($address: String!, $sessionKey: String) {
    validatorDetails(address: $address, sessionKey: $sessionKey) {
      name
      address
      faults
      inclusion
      discovered
      rank
      status
      valid
      inValidReason
      score
      commission
      rewards
    }
  }
`;

/**
 * __useValidatorDetailsQuery__
 *
 * To run a query within a React component, call `useValidatorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatorDetailsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useValidatorDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ValidatorDetailsQuery, ValidatorDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ValidatorDetailsQuery, ValidatorDetailsQueryVariables>(
    ValidatorDetailsDocument,
    options
  );
}
export function useValidatorDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidatorDetailsQuery, ValidatorDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ValidatorDetailsQuery, ValidatorDetailsQueryVariables>(
    ValidatorDetailsDocument,
    options
  );
}
export type ValidatorDetailsQueryHookResult = ReturnType<typeof useValidatorDetailsQuery>;
export type ValidatorDetailsLazyQueryHookResult = ReturnType<typeof useValidatorDetailsLazyQuery>;
export type ValidatorDetailsQueryResult = Apollo.QueryResult<
  ValidatorDetailsQuery,
  ValidatorDetailsQueryVariables
>;
export const HealthCheckDocument = gql`
  query HealthCheck($sessionKey: String) {
    healthCheck(sessionKey: $sessionKey)
  }
`;

/**
 * __useHealthCheckQuery__
 *
 * To run a query within a React component, call `useHealthCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthCheckQuery({
 *   variables: {
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useHealthCheckQuery(
  baseOptions?: Apollo.QueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HealthCheckQuery, HealthCheckQueryVariables>(HealthCheckDocument, options);
}
export function useHealthCheckLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HealthCheckQuery, HealthCheckQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HealthCheckQuery, HealthCheckQueryVariables>(
    HealthCheckDocument,
    options
  );
}
export type HealthCheckQueryHookResult = ReturnType<typeof useHealthCheckQuery>;
export type HealthCheckLazyQueryHookResult = ReturnType<typeof useHealthCheckLazyQuery>;
export type HealthCheckQueryResult = Apollo.QueryResult<
  HealthCheckQuery,
  HealthCheckQueryVariables
>;
export const MemberDetailsDocument = gql`
  query MemberDetails($accountId: String!, $sessionKey: String) {
    memberDetails(accountId: $accountId, sessionKey: $sessionKey) {
      account_id
      heartbeat
      network
      shardId
      sig
      state
      created_at
    }
  }
`;

/**
 * __useMemberDetailsQuery__
 *
 * To run a query within a React component, call `useMemberDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useMemberDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<MemberDetailsQuery, MemberDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MemberDetailsQuery, MemberDetailsQueryVariables>(
    MemberDetailsDocument,
    options
  );
}
export function useMemberDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MemberDetailsQuery, MemberDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MemberDetailsQuery, MemberDetailsQueryVariables>(
    MemberDetailsDocument,
    options
  );
}
export type MemberDetailsQueryHookResult = ReturnType<typeof useMemberDetailsQuery>;
export type MemberDetailsLazyQueryHookResult = ReturnType<typeof useMemberDetailsLazyQuery>;
export type MemberDetailsQueryResult = Apollo.QueryResult<
  MemberDetailsQuery,
  MemberDetailsQueryVariables
>;
export const ListAccountsDocument = gql`
  query ListAccounts($page: pageInputType, $sort: sortInputType, $sessionKey: String) {
    listAccounts(page: $page, sort: $sort, sessionKey: $sessionKey) {
      data {
        address
        data
        created_at
        updated_at
      }
      count
    }
  }
`;

/**
 * __useListAccountsQuery__
 *
 * To run a query within a React component, call `useListAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sort: // value for 'sort'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useListAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListAccountsQuery, ListAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAccountsQuery, ListAccountsQueryVariables>(
    ListAccountsDocument,
    options
  );
}
export function useListAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAccountsQuery, ListAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAccountsQuery, ListAccountsQueryVariables>(
    ListAccountsDocument,
    options
  );
}
export type ListAccountsQueryHookResult = ReturnType<typeof useListAccountsQuery>;
export type ListAccountsLazyQueryHookResult = ReturnType<typeof useListAccountsLazyQuery>;
export type ListAccountsQueryResult = Apollo.QueryResult<
  ListAccountsQuery,
  ListAccountsQueryVariables
>;
export const AccountDetailsDocument = gql`
  query AccountDetails($address: String!, $sessionKey: String) {
    accountDetails(address: $address, sessionKey: $sessionKey) {
      address
      data
      created_at
      updated_at
    }
  }
`;

/**
 * __useAccountDetailsQuery__
 *
 * To run a query within a React component, call `useAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDetailsQuery({
 *   variables: {
 *      address: // value for 'address'
 *      sessionKey: // value for 'sessionKey'
 *   },
 * });
 */
export function useAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(
    AccountDetailsDocument,
    options
  );
}
export function useAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountDetailsQuery, AccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AccountDetailsQuery, AccountDetailsQueryVariables>(
    AccountDetailsDocument,
    options
  );
}
export type AccountDetailsQueryHookResult = ReturnType<typeof useAccountDetailsQuery>;
export type AccountDetailsLazyQueryHookResult = ReturnType<typeof useAccountDetailsLazyQuery>;
export type AccountDetailsQueryResult = Apollo.QueryResult<
  AccountDetailsQuery,
  AccountDetailsQueryVariables
>;
export const NewBlocksDocument = gql`
  subscription NewBlocks {
    newBlocks {
      block {
        number
        hash
        status
        author
        parent_hash
        extrinsic_root
        created_at
      }
      extrinsicsCount
    }
  }
`;

/**
 * __useNewBlocksSubscription__
 *
 * To run a query within a React component, call `useNewBlocksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewBlocksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewBlocksSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewBlocksSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewBlocksSubscription,
    NewBlocksSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NewBlocksSubscription, NewBlocksSubscriptionVariables>(
    NewBlocksDocument,
    options
  );
}
export type NewBlocksSubscriptionHookResult = ReturnType<typeof useNewBlocksSubscription>;
export type NewBlocksSubscriptionResult = Apollo.SubscriptionResult<NewBlocksSubscription>;
export const NewExtrinsicsDocument = gql`
  subscription NewExtrinsics {
    newExtrinsics {
      id
      hash
      method
      created_at
    }
  }
`;

/**
 * __useNewExtrinsicsSubscription__
 *
 * To run a query within a React component, call `useNewExtrinsicsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewExtrinsicsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewExtrinsicsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewExtrinsicsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewExtrinsicsSubscription,
    NewExtrinsicsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NewExtrinsicsSubscription, NewExtrinsicsSubscriptionVariables>(
    NewExtrinsicsDocument,
    options
  );
}
export type NewExtrinsicsSubscriptionHookResult = ReturnType<typeof useNewExtrinsicsSubscription>;
export type NewExtrinsicsSubscriptionResult = Apollo.SubscriptionResult<NewExtrinsicsSubscription>;
export const TaskDocument = gql`
  subscription Task {
    newTasks {
      task {
        created_at
        salt
        definition
        destination
        id
        network
        payload
        phase
        shardId
        sig
        source
        sourceNetwork
        type
        childTask
        unAssignedReason
      }
    }
  }
`;

/**
 * __useTaskSubscription__
 *
 * To run a query within a React component, call `useTaskSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTaskSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTaskSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<TaskSubscription, TaskSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<TaskSubscription, TaskSubscriptionVariables>(TaskDocument, options);
}
export type TaskSubscriptionHookResult = ReturnType<typeof useTaskSubscription>;
export type TaskSubscriptionResult = Apollo.SubscriptionResult<TaskSubscription>;
